<template>
	<div class="permission_management">
		<div class="sec_wrap p20">
      <!-- 搜索 -->
      <div class="member_top">
        <el-button v-if="$has('552')" class="btnBgColor_blue" size="small" type="primary" icon="el-icon-plus"
        	@click="permissionBtn()">新增</el-button>
        <div class="input">
          <el-input placeholder="请输入角色名称" size="small" prefix-icon="el-icon-search" v-model="keyword"
          	@keyup.enter.native="search" @clear='search' :clearable="true">
          </el-input>
        </div>
      </div>
			<div class="table_wrap">
				<el-table :data="list" v-loading="loading" style="width: 100%"
					header-cell-class-name="table-header" cell-class-name="cell-class">
					<template slot="empty">
						<empty-data img='no_img_b.png' text='暂无数据'></empty-data>
					</template>
					<el-table-column prop="role_name" label="角色名称">
					</el-table-column>
					<el-table-column prop="member.username" label="创建人">
					</el-table-column>
          <el-table-column prop="member.mobile" label="创建人账号">
          </el-table-column>
          <el-table-column prop="num" label="成员数量">
            <template slot-scope="scope">
              <span class="table_span" @click="groupNumBtn(scope.row)">{{scope.row.has_num}}</span>
            </template>
          </el-table-column>
					<el-table-column prop="create_time" label="创建时间">
					</el-table-column>
					<el-table-column prop="update_time" label="修改时间">
					</el-table-column>
          <el-table-column prop="switch" label="是否启用">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
            </template>
          </el-table-column>
					<el-table-column class-name="last_class" label="操作" fixed="right" width="100">
						<template slot-scope="scope">
							<el-button type="text" size="small" v-if="$has('553')" @click="permissionBtn(scope.row)">编辑</el-button>
							<el-button type="text" size="small" v-if="$has('551')" @click="delBtn(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="page_box" v-if="total">
					<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
				</div>
			</div>
		</div>
    <!-- 温馨提示 -->
    <point-out ref='dialogTip' dialogTitle="温馨提示" type="qxDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">删除后所有绑定该角色的账号都不能使用该角色功能，您确认要删除该角色吗？</div>
      </div>
    </point-out>
	</div>
</template>
<script>
import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页

export default {
	components: {PointOut, EmptyData, PagingPage },
	props: {},
	data() {
		return {
      // 搜索
			keyword:'',
      // 列表
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			list: [],
      del_index:'',
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getList()
	},
	mounted() { },
	methods: {
		search() {
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		getList() {
      var that = this
      that.loading=true
      var params = {
        page: that.page,
        limit: that.limit,
        keyword:that.keyword
      }
      that.$personApi.getRole(params).then(res => {
        that.loading = false
        if (res.code == 1000) {
          that.list=res.result.data
          that.total=res.result.total
        } else {
          that.$errMsg(res.message)
        }
      })
		},
    // 改变每页条数
    sizeChangeBtn(val){
      this.limit = val
      this.page = 1
      this.getList()
    },
    // 改变页数
    currentChangeBtn(val){
      this.page = val
      this.getList()
    },
    // 成员管理
    groupNumBtn(info){
      if(this.$has('555')){
        this.$router.push({
          path:`/person/member/${info.id}`,
          query:{
            name:info.role_name
          }
        })
      }
    },
    // 修改状态
    changeSwitch(val,info,index){
      var that = this
      if(that.$has('554')){
        var params = {
          id: info.id,
          status: val
        }
        that.$personApi.roleStatus(params).then(res => {
          if (res.code == 1000) {
            that.$succMsg(res.message)
            that.list[index].status=val
          } else {
            that.$errMsg(res.message)
          }
        })
      }
    },
    // 删除
    delBtn(val){
      this.del_index=val
      this.$refs.dialogTip.openDialogBtn()
    },
    //确定删除
    closeListBtn(){
      var that = this
      if(that.del_index!==''){
        that.$personApi.delRole('',that.list[that.del_index].id).then(res => {
          if (res.code == 1000) {
            that.$succMsg(res.message)
            that.getList()
            // that.list.splice(this.del_index,1)
          } else {
            that.$errMsg(res.message)
          }
        })
      }
    },
    // 新增/编辑权限
		permissionBtn(info='') {
      if(info){
        this.$router.push({
          path:'/person/addEdit',
          query:{
            id:info.id,
            name:info.role_name
          }
        })
      }else{
        this.$router.push({
          path:'/person/addEdit',
        })
      }
		}
	},
}
</script>
<style lang='scss' scoped>
@import '../index.scss';
.permission_management {
  .member_top{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .el-button{
      margin-right: 20px;
    }
    .input{
      width: 186px;
      .el-input{
        width: 100%;
        ::v-deep .el-input__inner{
          border: none;
        }
      }
    }
  }
  .table_wrap .el-table{
    width: 100%;
    border-radius: 2px;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
  }
  .table_span{
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2E4BF2;
  }
  ::v-deep .last_class{
    border-left:1px solid #EBEEF5;
  }
}
</style>
